import { CREATE } from '../constants/actionTypes';

const userDataReducer = (state = { respData: null, errorMessage: null }, action) => {
    switch (action.type) {
        case CREATE:
            localStorage.setItem('requestSent', JSON.stringify({...action.payload }));
            return {...state, respData: action.payload };
        default:
            return state;
    }
}

export default userDataReducer;