import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { Home, PublicLayout } from '..';

const PageRouter = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<PublicLayout />} >
          <Route index element={ <Home />} />
        </Route>
      </Routes>
    </>
  )
}

export default PageRouter
