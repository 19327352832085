import React from 'react';
import coming_soon_banner from './img/Asset_1.png';

function ComingSoon(props) {
    return (
        <>
            <section style={{ paddingBottom: '0px' }}>
                <img
                    className="m-auto w-50"
                    src={coming_soon_banner}
                    alt="ComingSoon"
                />
            </section>
        </>
    );
}

export default ComingSoon;