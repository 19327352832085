import React from 'react'
import { Outlet } from 'react-router-dom'
import { Header, Footer } from '..';

const PublicLayout = () => {
  return (
    <>
      {/* <Header /> */}
      {/* <HeroSection /> */}
      <Outlet />
      {/* <Footer /> */}
    </>
  )
}

export default PublicLayout
