import React from 'react'
import { DownloadAppDisplayComponent, AppScreenShotComponent, AboutUs, Banner, ProductFeatures, LearningStrategies, ComingSoon } from '..';

const Home = () => {
  return (
    <div>
      <ComingSoon />
      {/* <Banner /> */}
      {/* <HeroSection /> */}
      {/* <AboutUs /> */}
      {/* <ProductFeatures /> */}
      {/* <AppScreenShotComponent /> */}
      {/* <LearningStrategies /> */}
      {/* <DownloadAppDisplayComponent /> */}
      {/* <FeaturesComponent /> */}
      {/* <Contact /> */}
    </div>
  )
}

export default Home
